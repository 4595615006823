export enum CityProviders {
  BOOKING = 'booking.com',
  VIATOR = 'viator',
  GENIUS = 'occasion_genius',
  VOICEGUIDE = 'voiceguide',
  ENROUTE = 'en-route',
  FLIGHTS = 'flights',
  CARRENTAL = 'carRental',
}
export const CITY_SERVICES = [
  {
    icon: 'ic-info.svg',
    type: 'city info',
    googleAnalytic: 'city info',
  },
  {
    icon: 'ic-hotels-b.svg',
    type: 'hotels',
    googleAnalytic: 'hotels',
    provider: CityProviders.BOOKING,
  },
  {
    icon: 'ic-activities-b.svg',
    type: 'activities',
    googleAnalytic: 'activites',
    provider: CityProviders.VIATOR,
  },
  {
    icon: 'ic-events-b.svg',
    type: 'events',
    googleAnalytic: 'events',
    provider: CityProviders.GENIUS,
  },
  {
    icon: 'ic-voice-guide-b.svg',
    type: 'voice guide',
    googleAnalytic: 'voice guide',
    provider: CityProviders.VOICEGUIDE,
  },
  {
    icon: 'ic-en-route-b.svg',
    type: 'en-route',
    googleAnalytic: 'enroute',
    provider: CityProviders.ENROUTE,
  },
  {
    icon: 'ic-flights-b.svg',
    type: 'flights',
    googleAnalytic: 'flights',
    provider: CityProviders.FLIGHTS,
  },
  {
    icon: 'ic-transportation-b.svg',
    type: 'car rental',
    googleAnalytic: 'transpotation',
    provider: CityProviders.CARRENTAL,
  },
];
