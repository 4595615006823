import { inject, Injectable } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { BehaviorSubject, map, Observable, of, switchMap, tap } from 'rxjs';

import { AuthService } from './auth.service';
import { WebApiService } from './web-api.service';

import { IHotelItem } from '../../shared/components/booking-hotel-card/booking-hotel-card.component';
import {
  AuthDialogComponent,
  EAuthPage,
} from '../../shared/dialogs/auth-dialog/auth-dialog.component';
import { GoogleAnalyticService } from './google-analytic.service';
import { BreakpointService } from './breakpoint.service';

export interface IFavoriteItem {
  additionalData: IHotelItem | any;
  favorite_id: string;
  place_id: string;
  provider: string;
  user_id: string;
}
export type FavoriteProvider = 'booking.com' | 'occasion_genius' | 'viator';
export interface IFavoriteRequest {
  placeId?: number;
  favoriteId?: string;
  provider?: FavoriteProvider;
}

@Injectable({
  providedIn: 'root',
})
export class FavoritesProviderService {
  onUpdateEvent$ = new BehaviorSubject<null>(null);
  #webApiService = inject(WebApiService);
  #authService = inject(AuthService);
  #matDialog = inject(MatDialog);

  storedFavourites: IFavoriteItem[] = [];
  private favoriteSet = new Set<string>();

  constructor() {}

  private get userId(): number {
    return this.#authService.userId;
  }

  public addToFavorites(
    payload: IFavoriteRequest,
    additionalData: Record<string, any> = {},
  ): Observable<boolean> {
    if (!this.userId) return of(false);

    const uniqueKey = `${payload.provider}_${payload.favoriteId}`;

    const newItem: IFavoriteItem = {
      additionalData: {},
      favorite_id: payload.favoriteId,
      place_id: String(payload.placeId),
      provider: payload.provider,
      user_id: String(this.userId),
    };

    if (!this.favoriteSet.has(uniqueKey)) {
      this.storedFavourites.push(newItem);
      this.favoriteSet.add(uniqueKey);
    }

    this.onUpdateEvent$.next(null);

    return this.#webApiService
      .post('favorites/add', this.attachUserIdToPayload(payload))
      .pipe(map(() => true));
  }

  public deleteFromFavorites(payload: {
    favoriteId: string;
    provider: FavoriteProvider;
  }): Observable<boolean> {
    if (!this.userId) return of(false);

    const uniqueKey = `${payload.provider}_${payload.favoriteId}`;

    this.storedFavourites = this.storedFavourites.filter(
      (item) => `${item.provider}_${item.favorite_id}` !== uniqueKey,
    );

    this.favoriteSet.delete(uniqueKey);
    this.onUpdateEvent$.next(null);

    return this.#webApiService
      .post('favorites/delete', this.attachUserIdToPayload(payload))
      .pipe(map(() => true));
  }

  public getAllFavorites(): Observable<{
    success: boolean;
    data: IFavoriteItem[];
  }> {
    if (!this.userId) return of({ success: false, data: [] });

    return this.#webApiService
      .post('favorites/getAll', this.attachUserIdToPayload({}))
      .pipe(tap((response) => this.resetState(response)));
  }

  public checkUserAuth(
    payload: IFavoriteRequest,
    additionalData: any,
    location: string,
    callback: (isDelete: boolean) => void,
  ): void {
    const isFavorite = this.isFavorite(payload.provider, payload.favoriteId);

    const request$ = isFavorite
      ? this.deleteFromFavorites({
          favoriteId: payload.favoriteId,
          provider: payload.provider,
        })
      : this.addToFavorites(payload, additionalData);

    if (this.userId) {
      request$.subscribe(() => callback(isFavorite));
    } else {
      this.#matDialog
        .open(AuthDialogComponent, {
          width: '1150px',
          maxWidth: '98vw',
          maxHeight: '98dvh',
          panelClass: 'mobile-container',
          data: {
            type: 'favourite',
            pageType: this.getPageType(payload.provider),
            location,
          },
        })
        .afterClosed()
        .pipe(
          switchMap(() => this.waitForUserId()),
          switchMap(() => {
            return isFavorite
              ? this.deleteFromFavorites({
                  favoriteId: payload.favoriteId,
                  provider: payload.provider,
                })
              : this.addToFavorites(payload, additionalData);
          }),
        )
        .subscribe(() => callback(isFavorite));
    }
  }

  private getPageType(provider: FavoriteProvider): EAuthPage {
    switch (provider) {
      case 'occasion_genius':
        return EAuthPage.FAVORITE;
      default:
        return EAuthPage.LOGIN;
    }
  }

  private attachUserIdToPayload(payload: object): object {
    return { userId: this.userId, ...payload };
  }

  public waitForUserId(): Observable<number> {
    return new Observable<number>((observer) => {
      const intervalId = setInterval(() => {
        const userId = this.userId;
        if (userId) {
          observer.next(userId);
          observer.complete();
          clearInterval(intervalId);
        }
      }, 100);
    });
  }

  public isFavorite(provider: FavoriteProvider, favoriteId: string): boolean {
    const uniqueKey = `${provider}_${favoriteId}`;
    return this.favoriteSet.has(uniqueKey);
  }

  public resetState(response: {
    success: boolean;
    data: IFavoriteItem[];
  }): void {
    if (!this.userId) return;

    if (response.success) {
      this.storedFavourites = response.data;

      const favoriteMap = new Map<string, IFavoriteItem>();

      response.data.forEach((item) => {
        const uniqueKey = `${item.provider}_${item.favorite_id}`;
        favoriteMap.set(uniqueKey, item);
      });

      this.favoriteSet = new Set(favoriteMap.keys());
    }
  }
}
