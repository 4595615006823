@if (breakpointService.isDesktop()) {
    <div class="settings-banner" [style.background-image]="bannerBgUrl"></div>
    <div class="settings-container">
        <div class="f-row f-between">
            <div class="settings-container-col">
                <h1 class="settings-container-col--title">
                    {{ 'PERSONAL_SETTINGS.TITLE' | translate }}
                </h1>

                <div class="settings-container-col--sub-title mb-25">
                    Hello {{ personalSettings().fname }}
                </div>
                <div class="settings-container-col--credentials">
                    Name: {{ personalSettings().fname }}
                </div>
                <div class="settings-container-col--credentials mb-25">
                    Email: {{ personalSettings().email }}
                </div>
                @if (savedTrips$ | async; as tripsAmount) {
                    <div class="settings-container-col--credentials mb-25">
                        You have <a routerLink="/account/saved-trips">{{ tripsAmount }} Saved Trips</a>
                    </div>
                }
                <form [formGroup]="userSettingsForm">

                    <div class="settings-container-col--sub-title">
                        Change name
                    </div>

                    <input type="text" formControlName="fname" class="settings--input" placeholder="Type your first name">
                    <input type="text" formControlName="lname" class="settings--input" placeholder="Type your last name">
                    <div class="settings-container-col--sub-title">
                        Change password
                    </div>

                    <input type="password" formControlName="oldPass" class="settings--input mb-25" style="margin-bottom: 40px"
                           placeholder="Type your current password">
                    <input type="password" formControlName="pass" class="settings--input" placeholder="Type new password">
                    <input type="password" formControlName="confirm" class="settings--input" placeholder="Retype new password">
                </form>
                <div class="f-row f-center mb-25">
                    <button class="btn-default btn-primary"
                            (click)="saveSettings()"
                    >
                        {{ 'ACTIONS.SAVE_CHANGES' | translate }}
                    </button>
                </div>
                <div class="f-row f-center">
                    <button class="btn-log-out__log-out" (click)="logStatus(false)">
                        Log out
                    </button>
                </div>
            </div>
            <div class="settings-container-col">
                <div class="settings-container-col-bg" [style.background-image]="contentBannerUrl">
                    <h4 class="settings-container-col-bg--title">
                        {{ "PERSONAL_SETTINGS.CONTENT.TITLE" | translate }}
                    </h4>
                    <h5 class="settings-container-col-bg--description">
                        {{ "PERSONAL_SETTINGS.CONTENT.SUBTITLE" | translate }}
                    </h5>
                    <h5 class="settings-container-col-bg--description">
                        {{ "PERSONAL_SETTINGS.CONTENT.DESCRIPTION" | translate }}
                    </h5>
                    <a class="btn-default btn-primary" target="_blank"
                       href="https://www.booking.com/?aid=7947415&selected_currency={{apiSettingsService.currency}}">
                        {{ "PERSONAL_SETTINGS.CONTENT.VIEWHOTELS" | translate }}
                    </a>
                </div>
            </div>
        </div>
    </div>
} @else {
    <div class="user-settings">
        <app-header-banner
                [localAsset]="true"
                [showContent]="false"
                [bannerImage]="'about_banner_mobile.jpg'"
        ></app-header-banner>

        <div class="user-settings__content">
            <h1>Hello {{ personalSettings().fname }}</h1>

            <div class="user-settings__info">
                <div class="user-settings__info--item">
                    <div class="user-settings__info--label">Name:</div>
                    <div class="user-settings__info--value">
                        @if(personalSettings().fname) {
                            {{personalSettings().fname}}
                        } @else {
                            <ngx-skeleton-loader [theme]="{width: '100px', height: '16px', margin: '0'}"></ngx-skeleton-loader>
                        }
                    </div>
                </div>

                <div class="user-settings__info--item">
                    <div class="user-settings__info--label">Email:</div>
                    <div class="user-settings__info--value">
                        @if(personalSettings().email) {
                            {{personalSettings().email}}
                        } @else {
                            <ngx-skeleton-loader [theme]="{width: '100px', height: '16px', margin: '0'}"></ngx-skeleton-loader>
                        }
                    </div>
                </div>
            </div>

            <form [formGroup]="userSettingsForm">
                <div class="user-settings__section">
                    <h2 class="user-settings__section--label">Personal information</h2>

                    <div class="user-settings__form">
                        <div class="user-settings__form--field">
                            <label class="user-settings__form--label">First name</label>

                            <mat-form-field appearance="fill">
                                <input
                                        type="text"
                                        formControlName="fname"
                                        placeholder="Type your first name"
                                        matInput
                                />
                                <mat-icon [svgIcon]="eSvgIcons.PERSON" matSuffix></mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="user-settings__form--field">
                            <label class="user-settings__form--label">Last name</label>
                            <mat-form-field appearance="fill">
                                <input
                                        type="text"
                                        formControlName="lname"
                                        placeholder="Type your first name"
                                        matInput
                                />
                                <mat-icon [svgIcon]="eSvgIcons.PERSON" matSuffix></mat-icon>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="user-settings__section">
                    <h2 class="user-settings__section--label">Change password</h2>

                    <div class="user-settings__form">
                        <div class="user-settings__form--field">
                            <label class="user-settings__form--label">Current password</label>

                            <mat-form-field appearance="fill">
                                <input
                                        type="text"
                                        formControlName="oldPass"
                                        placeholder="Type your current password"
                                        matInput
                                />
                                <mat-icon [svgIcon]="eSvgIcons.AUTH_LOCK" matSuffix></mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="user-settings__form--field">
                            <label class="user-settings__form--label">New password</label>
                            <mat-form-field appearance="fill">
                                <input
                                        type="text"
                                        formControlName="pass"
                                        placeholder="Type new password"
                                        matInput
                                />
                                <mat-icon [svgIcon]="eSvgIcons.AUTH_LOCK" matSuffix></mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="user-settings__form--field">
                            <label class="user-settings__form--label">Retype new password</label>
                            <mat-form-field appearance="fill">
                                <input
                                        type="text"
                                        formControlName="confirm"
                                        placeholder="Retype new password"
                                        matInput
                                />
                                <mat-icon [svgIcon]="eSvgIcons.AUTH_LOCK" matSuffix></mat-icon>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="user-settings__actions">
                    <div class="user-settings__actions--submit">
                        <button class="btn-default btn-blue btn-mobile" (click)="saveSettings()">Save changes</button>
                    </div>
                    <div class="user-settings__actions--logout">
                        <button class="btn-default btn-logout btn-mobile" (click)="logStatus(false)">Log out</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
}
