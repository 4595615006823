import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';

import { map, Observable, shareReplay } from 'rxjs';

import { ERoutes } from '../../../core/enums/routes.enum';
import { ESvgIcons } from '../../../core/enums/svg-icons';
import { IPersonalSettings } from '../../../core/types/interfaces/personal-settings.interface';

import { AuthService } from '../../../core/services/auth.service';
import { NewAuthService } from '../../../core/services/new-auth.service';
import { SsrHelperService } from '../../../core/services/ssr-helper.service';
import { MyTrips, TripsService } from '../../../core/services/trips.service';
import { BreakpointService } from '../../../core/services/breakpoint.service';
import { CloudinaryService } from '../../../core/services/cloudinary.service';
import { ApiSettingsService } from '../../../core/services/api-settings.service';
import { DomainTrackingService } from '../../../core/services/domain-tracking.service';
import { MetaTagsConfigService } from '../../../core/services/meta-tags-config.service';

import { Config } from '../../../core/configs/config';
import { GoogleAnalyticService, HeaderDesktopEvent } from "../../../core/services/google-analytic.service";

export enum ESubscribeNewLatter {
  NO,
  YES,
}

@Component({
    selector: 'app-user-settings',
    templateUrl: './user-settings.component.html',
    styleUrls: ['./user-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UserSettingsComponent implements OnInit, OnDestroy {
  public bannerBgUrl = `${Config.imgCdnUrl}images/user-settings/settings-banner.jpg`;
  public contentBannerUrl = `${Config.imgCdnUrl}images/user-settings/settings-content-bg.jpg`;
  public userSettingsForm: UntypedFormGroup;
  public isLogged: boolean;
  public isChangePassword: boolean = true;

  protected personalSettings: WritableSignal<IPersonalSettings> =
    signal<IPersonalSettings>({} as IPersonalSettings);
  protected savedTrips$: Observable<number>;
  protected readonly eSvgIcons = ESvgIcons;

  constructor(
    private router: Router,
    public authService: AuthService,
    private domainTrackingService: DomainTrackingService,
    private metaTagsConfigService: MetaTagsConfigService,
    private newAuthService: NewAuthService,
    private ssrHelperService: SsrHelperService,
    private readonly tripsService: TripsService,
    private googleAnalyticService: GoogleAnalyticService,
    public readonly apiSettingsService: ApiSettingsService,
    protected readonly breakpointService: BreakpointService,
    protected readonly cloudinaryService: CloudinaryService,
  ) {
    this.metaTagsConfigService.setMetaTags(ERoutes.SETTINGS);
    this.bannerBgUrl = `url(${this.cloudinaryService.getCloudImage(
      this.bannerBgUrl,
      true,
    )})`;
    this.contentBannerUrl = `url(${this.cloudinaryService.getCloudImage(
      this.contentBannerUrl,
      true,
    )})`;
  }

  ngOnInit(): void {
    this.initFormGroup();
    this.toggleChangePassword();

    this.savedTrips$ = this.tripsService.getMyTrips().pipe(
      map((res: MyTrips) => res.tripsCount),
      shareReplay(1),
    );
  }

  ngOnDestroy(): void {
    this.metaTagsConfigService.removeMetaTags();
  }

  saveSettings(): void {
    this.checkSubscribeNewLetter();
    this.newAuthService
      .updatePersonalSettings(this.userSettingsForm.getRawValue())
      .subscribe(() => {
        const userProfile = this.authService.userProfile;
        userProfile.name = this.userSettingsForm.get('fname').value;
        userProfile.lastName = this.userSettingsForm.get('lname').value;
        const oldSettings = this.personalSettings();
        this.personalSettings.set({
          ...oldSettings,
          ...this.userSettingsForm.value,
        });
      });
  }

  logStatus(isLogged: boolean): void {
    this.authService.logStatus(isLogged);
    this.isLogged = isLogged;

    if(!isLogged) {
      this.setHeaderAnalytic('signout')
    }

    this.router
      .navigateByUrl(this.domainTrackingService.getPostLogoutRoute())
      .then();
  }

  setHeaderAnalytic(event: HeaderDesktopEvent): void {
    if (this.breakpointService.isMobile()) {
      this.googleAnalyticService.setHeaderMobileAnalytic({
        header_click: event,
        userID: this.authService.userId,
      });
    } else {
      this.googleAnalyticService.setHeaderDesktopAnalytic({
        header_click: event,
        userID: this.authService.userId,
      });
    }
  }

  toggleChangePassword(): void {
    this.isChangePassword = false;
    this.userSettingsForm.addControl(
      'oldPass',
      new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(32),
        this.checkIncludedSpaceSymbolValidator(),
      ]),
    );
    this.userSettingsForm.addControl(
      'pass',
      new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(32),
        this.checkIncludedSpaceSymbolValidator(),
      ]),
    );
    this.userSettingsForm.addControl(
      'confirm',
      new UntypedFormControl('', [Validators.required, this.mustMatch('pass')]),
    );
  }

  private initFormGroup(): void {
    this.userSettingsForm = new UntypedFormGroup({
      fname: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(20),
        Validators.pattern(
          /^[a-zA-Z]{1,20}-?(\s[a-zA-Z]{1,}-?)?(\s?[a-zA-Z]{1,}-?)?$/,
        ),
      ]),
      lname: new UntypedFormControl(''),
      subscribe: new UntypedFormControl(false),
    });

    if (this.ssrHelperService.userAgent) {
      this.setUserSettings();
    }
  }

  private checkIncludedSpaceSymbolValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value?.includes(' ')) {
        return { hasSpaceSymbol: true };
      }
      return null;
    };
  }

  private mustMatch(
    matchingControlName: string,
  ): (formGroup: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl) => {
      const matchingControl = control.parent?.get(matchingControlName);
      if (control?.value !== matchingControl?.value) {
        return { mustMatch: true };
      }
      return null;
    };
  }

  private checkSubscribeNewLetter(): void {
    const sub = this.userSettingsForm.get('subscribe');
    if (sub.value) {
      sub.patchValue(ESubscribeNewLatter.YES);
      return;
    }

    sub.patchValue(ESubscribeNewLatter.NO);
  }

  private setUserSettings(): void {
    this.newAuthService.getPersonalSettings().subscribe((res) => {
      this.personalSettings.set(res);
      this.userSettingsForm.patchValue(res);
      this.userSettingsForm.markAsPristine();
    });
  }
}
