import { Injectable } from '@angular/core';

import { CITY_SERVICES, CityProviders } from '../utils/city-services';

import { TripPlannerInteractionComponent } from '../../modules/new-trip-planner/trip-planner-interaction/trip-planner-interaction.component';

@Injectable({
  providedIn: 'root',
})
export class SchedulerBlockNavigationService {
  #isScheduled: boolean = false;

  #cityProvider: CityProviders;
  #cityIndex: number;

  public executeNavigation(
    componentRef: TripPlannerInteractionComponent,
  ): void {
    if (!this.#isScheduled) return;

    const serviceIndex = CITY_SERVICES.findIndex(
      (i) => i.provider === this.#cityProvider,
    );
    componentRef.cityInfoIndex = this.#cityIndex;
    componentRef.selectService(serviceIndex);
    this.#isScheduled = false;
    this.#cityProvider = this.#cityIndex = null;
    setTimeout(() => (componentRef.isUpdatingByUserInteraction = false), 250);
  }

  public scheduleNavigation(
    cityIndex: number,
    cityProvider: CityProviders,
  ): void {
    this.#cityIndex = cityIndex;
    this.#cityProvider = cityProvider;
    this.#isScheduled = true;
  }

  get tripPlannerBlockIsScheduled(): boolean {
    return this.#isScheduled;
  }
}
